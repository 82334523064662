<template>
  <div>
    <PreLoader v-if="isLoading" />
    <div class="reports-card">
      <a v-b-modal.ReportSellCards class="">
        <img src="@/assets/images/sellCards.svg" />{{
          $t("SellCards.modelName")
        }}
      </a>
    </div>

    <b-modal id="ReportSellCards" scrollable centered size="xl" hide-footer>
      <template #modal-title>
        <h3>
          <img src="@/assets/images/sellCards.svg" class="icon-lg" />
          {{ $t("SellCards.modelName") }}
        </h3>
      </template>

      <form autocomplete="off" method="post">
        <div class="row">
          <DatePicker
            :className="'col-md-6'"
            :id="'dateFrom'"
            :value="sellCards.filterData.dateFrom"
            v-on:changeValue="sellCards.filterData.dateFrom = $event"
            :title="$t('dateFrom')"
            :language="language"
          />
          <TimePicker
            :className="'col-md-6'"
            :value="sellCards.filterData.timeFrom"
            v-on:changeValue="sellCards.filterData.timeFrom = $event"
            :title="$t('timeFrom')"
            :language="language"
          />
          <DatePicker
            :className="'col-md-6'"
            :id="'dateTo'"
            :value="sellCards.filterData.dateTo"
            v-on:changeValue="sellCards.filterData.dateTo = $event"
            :title="$t('dateTo')"
            :language="language"
          />
          <TimePicker
            :className="'col-md-6'"
            :value="sellCards.filterData.timeTo"
            v-on:changeValue="sellCards.filterData.timeTo = $event"
            :title="$t('timeTo')"
            :language="language"
          />
          <CustomSelectBoxWithImg
            :className="'col-md-12'"
            :id="'createdByUserToken'"
            :value="sellCards.filterData.createdByUserToken"
            :options="userTokenOptions"
            v-on:changeValue="sellCards.filterData.createdByUserToken = $event"
            :title="$t('employees.selectCreated')"
            :imgName="'users.svg'"
          />
          <CustomSelectBoxWithImg
            :className="'col-md-12'"
            :id="'lastUpdatedByUserToken'"
            :value="sellCards.filterData.lastUpdatedByUserToken"
            :options="userTokenOptions"
            v-on:changeValue="
              sellCards.filterData.lastUpdatedByUserToken = $event
            "
            :title="$t('employees.selectLastUpdated')"
            :imgName="'users.svg'"
          />
          <CustomInput
            :className="'col-md-12'"
            :id="'sendTo'"
            :value="sellCards.filterData.sendTo"
            v-on:changeValue="sellCards.filterData.sendTo = $event"
            :title="$t('email')"
            :imgName="'email.svg'"
          />

          <CustomInput
            :className="'col-md-12'"
            :id="'textSearch'"
            :value="sellCards.filterData.textSearch"
            v-on:changeValue="sellCards.filterData.textSearch = $event"
            :title="$t('search')"
            :imgName="'search.svg'"
          />
        </div>

        <div class="modal-footer">
          <button
            name="submit"
            type="submit"
            class="btn btn-submit"
            @click.prevent="getReportSellCards()"
          >
            {{ $t("viewAndSend") }}
          </button>
          <button
            name="submit"
            type="submit"
            class="btn btn-cancel"
            @click.prevent="$bvModal.hide('ReportSellCards')"
          >
            {{ $t("cancel") }}
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import { STATUS } from "@/utils/constants";
import { setDataMultiLang } from "@/utils/functions";
import CustomInput from "@/components/general/CustomInput.vue";
import DatePicker from "@/components/general/DatePicker.vue";
import TimePicker from "@/components/general/TimePicker.vue";
import SellCards from "@/models/sellCards/SellCards";
import createToastMixin from "@/utils/create-toast-mixin";
import { USER_TYPE } from "@/utils/constantLists";
import Users from "@/models/users/Users";
import CustomSelectBoxWithImg from "@/components/general/CustomSelectBoxWithImg.vue";

export default {
  name: "ReportSellCards",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    CustomInput,
    DatePicker,
    TimePicker,
    CustomSelectBoxWithImg,
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      userTokenOptions: [],
      users: new Users(),
      sellCards: new SellCards(),
    };
  },
  methods: {
    async getReportSellCards() {
      this.isLoading = true;
      try {
        let response = await this.sellCards.sellCard.getReportSellCards(
          this.language,
          this.userAuthorizeToken,
          this.sellCards.pagination,
          this.sellCards.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.hide("ReportSellCards");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getUsersDialog() {
      this.isLoading = true;
      try {
        this.users.filterData.userTypeToken = USER_TYPE.Employee;
        this.userTokenOptions = await this.users.user.getUsersDialog(
          this.language,
          this.userAuthorizeToken,
          this.users.filterData
        );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },

    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  async created() {
    this.getUsersDialog();
  },
};
</script>
